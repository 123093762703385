import axios from 'axios';
import React, { use, useRef, useState } from 'react';
import './App.css'; // CSS for layout
import ChatBox from './components/ChatBox';
import Modal from './components/Modal';
import Sidebar from './components/Sidebar';
import WhatsAppButton from './components/WhatsAppButton';
import { getDeviceId } from "./Fingerprint";
import WhatsappCard from './components/WhatsappCard';
import WhatsappCardDialog from './components/WhatsappCard';


// API URLs for sending and verifying OTP
const API_URL = process.env.REACT_APP_API_URL;
const SEND_OTP_API_URL = process.env.REACT_APP_SEND_OTP_API_URL;
const VERIFY_OTP_API_URL = process.env.REACT_APP_VERIFY_OTP_API_URL;
const SAVE_USER_API_URL = process.env.REACT_APP_SAVE_USER_API_URL;

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [otpSent, setOtpSent] = useState(false); // OTP sent state
  const [userDetails, setUserDetails] = useState({ name: '', email: '', phone: '' }); // User details state
  const [otp, setOtp] = useState(''); // OTP input state
  const [timer, setTimer] = useState(120); // Timer state for OTP (2 minutes)
  const [messages, setMessages] = useState([
    { role: 'assistant', content: 'Welcome to Capri AI-Dost! \nHow may I assist you today? \nमैं आपकी कैसे मदद कर सकता हूं? \nહું તમને કેવી રીતે મદદ કરી શકું? \n मी तुम्हाला कशी मदत करू शकतो?' }
  ]);
  const [loading, setLoading] = useState(false); // Loading state for responses

  const otpTimerRef = useRef(null); // Reference to store interval ID

  // Helper function to validate user details
  const validateUserDetails = (details) => {
    const { name, email, phone } = details;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;

    if (!name.trim()) {
      alert("Name is required.");
      return false;
    }
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return false;
    }
    if (!phoneRegex.test(phone)) {
      alert("Please enter a valid 10-digit phone number.");
      return false;
    }
    return true;
  };

  // Function to send user message to backend and get response
  const handleSendMessage = async (userMessage) => {
    const newMessages = [...messages, { role: 'user', content: userMessage }];
    setMessages(newMessages);
    setLoading(true); // Start loading when message is sent

    try {
      const deviceId = await getDeviceId();
      const response = await axios.post(API_URL, {
        query_text: userMessage,
        knowledge_base_id: "capriglobalai",
        device_id: ''+ deviceId,
        source: "web"
      });
      const assistantMessage = response.data.response || "Sorry, I didn't understand that.";
      setMessages(prevMessages => [
        ...prevMessages,
        { role: 'assistant', content: assistantMessage }
      ]);
    } catch (error) {
      console.error('Error fetching response:', error);
      setMessages(prevMessages => [
        ...prevMessages,
        { role: 'assistant', content: "Sorry, something went wrong with the server." }
      ]);
    } finally {
      setLoading(false); // Stop loading after response is received
    }
  };

  // Handle opening the WhatsApp modal
  const handleWhatsAppClick = () => {
    setIsModalOpen(true); // Open the modal when WhatsApp button is clicked
  };

  // Handle closing the modal and resetting the form
  const closeModal = () => {
    setIsModalOpen(false);
    setOtpSent(false);  // Reset OTP sent status
    setUserDetails({ name: '', email: '', phone: '' });  // Reset user details
    setOtp('');  // Reset OTP input
    setTimer(30); // Reset the timer

    // Clear the OTP timer if it exists
    if (otpTimerRef.current) {
      clearInterval(otpTimerRef.current);
      otpTimerRef.current = null; // Reset the reference
    }
  };

  // Handle OTP request (user details submission) and SMS sending
  const handleOtpRequest = async (details) => { 
    // if (!validateUserDetails(details)) return; // Validate user details before proceeding

    try {
      // Step 1: Send OTP request
      setButtonDisabled(true);
      const otpResponse = await axios.get(`${SEND_OTP_API_URL}?dest=${details.phone}&otplen=4`);
      console.log('OTP Response:', otpResponse.status);
      if (otpResponse.status === 200) {
        setUserDetails(details);
        setTimer(120); // Reset timer
        alert('SMS sent successfully with OTP!');
        setOtpSent(true);
        setButtonDisabled(false);

        // Step 3: Start OTP timer (2 minutes) and store the interval ID in otpTimerRef
        otpTimerRef.current = setInterval(() => {
          setTimer(prev => {
            if (prev === 0) {
              clearInterval(otpTimerRef.current); // Clear the timer when it reaches 0
              otpTimerRef.current = null; // Reset the reference
            }
            return prev - 1;
          });
        }, 1000);
      } else {
        alert('Error sending OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP or SMS:', error);
      alert('Error sending OTP or SMS. Please try again.');
      setButtonDisabled(false);
    }
  };

  // Handle OTP verification
  const handleOtpVerification = async (otp) => {
    try {
      // Verify OTP request
      setButtonDisabled(true);
      const response = await axios.get(`${VERIFY_OTP_API_URL}?dest=${userDetails.phone}&otp=${otp}`);

      if (response.status === 202) {

        // Save user and lead details and call lead gen api
        const deviceId = await getDeviceId();
        const saveUserResponse = await axios.post(SAVE_USER_API_URL, {
          device_id: '' + deviceId,
          phone_no: '' + userDetails.phone,
          name: '' + userDetails.name,
          productType: "HOME_LOAN",
          leadSource: "DS_AI",
          externalLeadId: "DS_AI_website"
        });

        alert('OTP verified successfully!');
        closeModal(); // Close the modal after successful OTP verification

        // Clear the OTP timer when OTP is verified
        setButtonDisabled(false);
        if (otpTimerRef.current) {
          clearInterval(otpTimerRef.current);
          otpTimerRef.current = null; // Reset the reference
        }

        // const whatsappUrl = `https://wa.me/${9667764969}?text=Hello%20${encodeURIComponent(userDetails.name)},%20welcome%20to%20our%20service!`;
        // window.location.href = whatsappUrl
      } else {
        alert('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert(error?.response?.data?.msg || 'Error verifying OTP. Please try again.');
      setButtonDisabled(false);
    }
  };

  return (
    <div className="App">
      <Sidebar />
      <div className="main-content">
        <div className="welcome-section">
          <h2>Welcome to Capri AI-Dost!</h2>
          <p>
            Legal Disclaimer: The information provided by this AI assistant is for general informational purposes only
            and does not constitute financial, legal, or professional advice. Users are encouraged to consult with a 
            qualified financial advisor or institution before making any decisions. The accuracy of the information is 
            not guaranteed, and we assume no liability for any actions taken based on the guidance provided by the assistant.
          </p>
        </div>

        {/* Chat Section */}
        <div className="chat-section">
          <ChatBox messages={messages} onSendMessage={handleSendMessage} loading={loading} />
          <div className="final-disclaimer">
              Disclaimer: I am an AI and may make mistakes.
          </div>
        </div>
        <WhatsappCardDialog handleWhatsAppClick = {handleWhatsAppClick}/>
      </div>
      {/* <WhatsAppButton onClick={handleWhatsAppClick} /> Trigger modal when clicked */}
      
      {/* OTP Modal */}
      <Modal
        show={isModalOpen}
        onClose={closeModal}
        otpSent={otpSent}
        onOtpRequest={handleOtpRequest}
        onOtpVerification={handleOtpVerification}
        buttonDisabled={buttonDisabled}
        timer={timer}
      />
    </div>
  );
}

export default App;
