import React from 'react';
import './style.css';
import WhatsappCard from '../../assets/WhatsappCard.png';

const WhatsappCardDialog = ({handleWhatsAppClick}) => {
  return (
    <div className='container' onClick={handleWhatsAppClick}>
    <img src={WhatsappCard} alt="WhatsApp Logo" className="whatsapp-card-icon" />
    <div className='text-container'>
        <span className="text text-main">Continue on Whatsapp</span>
        <p className="text text-sub">or provide your details</p>
    </div>
    </div>
  );
};

export default WhatsappCardDialog;
