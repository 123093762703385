import React, { useState } from 'react';
import './Modal.css'; // Import modal styling

function Modal({ show, onClose, otpSent, onOtpRequest, onOtpVerification, timer, buttonDisabled }) {
  const [details, setDetails] = useState({ name: '', product_type: '', phone: '' });
  const [otp, setOtp] = useState('');

  if (!show) return null; // Don't render modal if not visible

  // Handle user input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  // Handle OTP input change
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  // Handle form submission for user details
  const handleSubmit = (e) => {
    e.preventDefault();
    onOtpRequest(details);
  };

  // Handle OTP verification
  const handleOtpSubmit = (e) => {
    e.preventDefault();
    onOtpVerification(otp);
    setOtp('');
  };

  const handleWhatsAppRedirection = () => {
    const phoneNumber = "9266344464";
    const message = "Welcome to Capri AI-Dost!";
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, "_blank"); // Opens the WhatsApp link in a new tab
  }

  return (
    <div className="modal-overlay">
      <div className="modal-box">
        {/* Close button */}
        
        <button className="modal-close-btn" onClick={onClose}>×</button> {/* Close button added here */}

        <h2>लोन लेना अब है आसान,<br/>AI दोस्त के संग छुएं आसमान</h2>

        {!otpSent ? (
        <div className='redirect-whatsapp' onClick={handleWhatsAppRedirection}>
          <p>Continue on Whatsapp</p>
          <img 
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" 
          alt="WhatsApp" 
          style={{ width: '50px', height: '50px' }} 
        />
        </div>
        ) : null}

        {!otpSent ? (
          <div class="hr-with-text">
            <hr />
            <span>OR</span>
            <hr />
          </div>
        ) : null
        }
                
        {!otpSent ? (
          // User details form (before OTP is sent)
          <>
             <p style={{fontSize: '18px', marginBottom: '10px' , fontWeight:'bold'}}>Provide Details Here</p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={details.name}
              onChange={handleChange}
              required
            />
            
            {/* <select 
              placeholder="Product Type"
              name="product_type"
              value={details.product_type}
              onChange={handleChange}
              >
              <option value="">Product Type</option>
              <option value="HOME_LOAN">Home Loan</option>
              <option value="MSME_LOAN">Loan against property(LAP)</option>
              <option value="GOLD_LOAN">Gold loan</option>
              <option value="CAR_LOAN">Car loan or Others</option>
            </select> */}
            
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={details.phone}
              onChange={handleChange}
              maxLength={10}
              required
            />
            <button type="submit" disabled={buttonDisabled}>सबमिट करें</button>
          </form>
          </>
        ) : (
          // OTP input form (after OTP is sent)
          <form onSubmit={handleOtpSubmit}>
            <input
              type="text"
              name="otp"
              placeholder="Enter OTP"
              value={otp}
              onChange={handleOtpChange}
              required
            />
            <div className="otp-buttons">
              <button type="button" onClick={handleSubmit} disabled={timer > 0}>OTP फिर से भेजें</button>
              <button type="submit" disabled={buttonDisabled}>सबमिट करें</button>
            </div>
            <p>OTP आपके मोबाइल नंबर पर भेजा जा चुका है। <br /> समय शेष: <strong>{`${Math.max(Math.floor(timer / 60), 0)}:${String(Math.max(timer % 60, 0)).padStart(2, '0')}`}</strong></p>
          </form>
        )}
      </div>
    </div>
  );
}

export default Modal;
